import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "../../styles/responsive_modal.css";
import close from "../../assets/icons/croix.png";
import Slider from "react-slick";

const ResponsiveModalComponent = ({
  modals,
  buttonImage,
  buttonText,
  modalIsOpen,
  onOpen,
  modalPage,
  image,
  subTitle,
  description,
}) => {
  const [isOpen, setIsOpen] = useState(modalIsOpen);
  const [overlayClass, setOverlayClass] = useState("slide-in");

  useEffect(() => {
    setIsOpen(modalIsOpen);
    if (modalIsOpen) {
      setOverlayClass("slide-in");
    }
  }, [modalIsOpen]);

  const closeModal = () => {
    setOverlayClass("slide-out");
    setTimeout(() => {
      setIsOpen(false);
      onOpen(null);
      // const element = document.querySelector(".hide");
      // if (element) {
      //   element.classList.remove("hide");
      // }
    }, 500); // correspond à la durée de l'animation
  };

  const [imagesArray, setImagesArray] = useState([]);

  useEffect(() => {
    if (modals[0].imagesList !== undefined) {
      const imagesArray = Object.values(modals[0].imagesList);
      setImagesArray(imagesArray);
    }
  }, []);

  const settings = {
    dots: true,
    arrow: true,
    infinite: true,
    speed: 500,
    focusOnSelect: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      {modalPage == "portfolio" ? (
        <div className="carousel-item color-pink" onClick={() => onOpen(true)}>
          <div className="carousel-item-header">
            <h2>{buttonText}</h2>
          </div>
          <div className="portfolio-item-content">
            <img src={image} alt={buttonText} />
            <h3>{subTitle}</h3>
            <p>{description}</p>
          </div>
        </div>
      ) : (
        <button onClick={() => onOpen(true)} className="open-modal-popup">
          <img src={buttonImage} alt="Bouton d'ouverture de modal" />
          <p>{buttonText}</p>
        </button>
      )}
      {isOpen &&
        modals.map((modal, index) => (
          <Modal
            key={index}
            isOpen={isOpen}
            onRequestClose={closeModal}
            className="modal-container"
            overlayClassName={`modal-overlay ${overlayClass}`}
          >
            <div className="modal">
              <div className="modal-header">
                <h2>{modal.title}</h2>
                <img src={close} alt="cross" onClick={closeModal} />
              </div>
              <div className="modal-content">
                <div
                  className="modal-text"
                  dangerouslySetInnerHTML={{ __html: modal.text }}
                ></div>
                {modals[0].imagesList !== undefined && (
                  <Slider {...settings}>
                    {imagesArray.map((image, index) => (
                      <img key={index} src={image} alt={`slide-${index}`} />
                    ))}
                  </Slider>
                )}
                {modal.subText !== undefined && (
                  <div
                    className="modal-sub-text"
                    dangerouslySetInnerHTML={{ __html: modal.subText }}
                  ></div>
                )}
              </div>
            </div>
          </Modal>
        ))}
    </div>
  );
};

export default ResponsiveModalComponent;
