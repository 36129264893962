import React, { useEffect, useState } from "react";
import "../../styles/cursor.css";

const CustomCursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isPointer, setIsPointer] = useState(false);

  useEffect(() => {
    const moveCursor = (e) => {
      let x = e.clientX;
      let y = e.clientY;

      // Limiter le curseur à la fenêtre visible (viewport)
      const cursorSize = 30; // Rayon du curseur (40px / 2)
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      // Limiter la position du curseur
      x = Math.max(cursorSize, Math.min(x, windowWidth - cursorSize));
      y = Math.max(cursorSize, Math.min(y, windowHeight - cursorSize));

      setPosition({ x, y });

      const target = e.target;
      if (
        target.tagName === "A" ||
        target.tagName === "BUTTON" ||
        target.classList.contains("clickable") ||
        target.classList.contains("open-draggable-popup") ||
        target.closest(".popup-header .close") ||
        target.classList.contains("landing-button") ||
        target.classList.contains("accordion-header") ||
        target.closest("header .nav img") ||
        target.classList.contains("newsletter-popup") ||
        target.closest(".newsletter-popup button") ||
        target.classList.contains("portfolio .carousel-item") ||
        target.classList.contains("portfolio-return-button") ||
        target.classList.contains("open-modal-popup")
      ) {
        setIsPointer(true);
      } else {
        setIsPointer(false);
      }
    };

    window.addEventListener("mousemove", moveCursor);

    return () => {
      window.removeEventListener("mousemove", moveCursor);
    };
  }, []);

  return (
    <div
      className={`custom-cursor ${isPointer ? 'pointer' : ''}`}
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
      }}
    >
      <div className="center-ball" />
    </div>
  );
};

export default CustomCursor;
