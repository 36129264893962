import DraggablePopupComponent from "../../components/DraggablePopup";
import rond from "../../assets/icons/rond_service.png";
import rond_lila from "../../assets/icons/rond_service_lila.png";

import "../../styles/portfolio.css";

import { usePopupContext } from "../../context/PopupContext";
import { useEffect, useState } from "react";
import CarouselPortfolioResponsive from "../../components/CarouselPortfolioResponsive";
import { Helmet } from "react-helmet-async";
import Slider from "react-slick";
import flecheGaucheLilas from "../../assets/icons/fleche_gauche_lilas.png";
import flecheGaucheRose from "../../assets/icons/fleche_gauche_rose.png";

/* PORTFOLIO IMAGES */
// Avenir Investissements
import avenirInvestissementsMockupApron from "../../assets/images/portfolio/Avenir_investissements/mockup_apron.jpg";
import avenirInvestissementsMockupBag from "../../assets/images/portfolio/Avenir_investissements/mockup_bag.jpg";
import avenirInvestissementsMockupBagGrocer from "../../assets/images/portfolio/Avenir_investissements/mockup_bag_grocer.jpg";
import avenirInvestissementsMockupBanner from "../../assets/images/portfolio/Avenir_investissements/mockup_banner.jpg";
import avenirInvestissementsMockupFlyer from "../../assets/images/portfolio/Avenir_investissements/mockup_flyer.jpg";
import avenirInvestissementsMockupOutside from "../../assets/images/portfolio/Avenir_investissements/mockup_outside.jpg";
import avenirInvestissementsMockupPins from "../../assets/images/portfolio/Avenir_investissements/mockup_pins.jpg";
import avenirInvestissementsMockupPoster from "../../assets/images/portfolio/Avenir_investissements/mockup_poster.jpg";
import avenirInvestissementsMockupSign from "../../assets/images/portfolio/Avenir_investissements/mockup_sign.jpg";
import avenirInvestissementsMockupSign2 from "../../assets/images/portfolio/Avenir_investissements/mockup_sign2.jpg";
import avenirInvestissementsMockupWall from "../../assets/images/portfolio/Avenir_investissements/mockup_wall.jpg";
// IRIS IT
import irisItMockupCarteVoeux from "../../assets/images/portfolio/IRIS_IT/mockup_carte_voeux.jpg";
import irisItMockupFlyer from "../../assets/images/portfolio/IRIS_IT/mockup_flyer.jpg";
import irisItMockupKakemono from "../../assets/images/portfolio/IRIS_IT/mockup_kakemono.jpg";
import irisItMockupPlaquetteMetiers from "../../assets/images/portfolio/IRIS_IT/mockup_plaquette_metiers.jpg";
import irisItMockupSocialMedia from "../../assets/images/portfolio/IRIS_IT/mockup_social_media.jpg";
// Mobile Angelo
import mobileAngeloMockupBanner from "../../assets/images/portfolio/Mobile_Angelo/mockup_banner.jpg";
import mobileAngeloMockupInstaPost from "../../assets/images/portfolio/Mobile_Angelo/mockup_insta_post.jpg";
import mobileAngeloMockupTelephone from "../../assets/images/portfolio/Mobile_Angelo/mockup_telephone.jpg";
import mobileAngeloMockupSite from "../../assets/images/portfolio/Mobile_Angelo/mockup_site.jpg";
// NH Accompagnements
import nhAccompagnementsMockupInstaPost from "../../assets/images/portfolio/NH_Accompagnements/mockup_insta_post.jpg";
import nhAccompagnementsMockupSite from "../../assets/images/portfolio/NH_Accompagnements/mockup_site.jpg";
import nhAccompagnementsMockupTablette from "../../assets/images/portfolio/NH_Accompagnements/mockup_tablette.jpg";
import nhAccompagnementsMockupTelephone from "../../assets/images/portfolio/NH_Accompagnements/mockup_telephone.jpg";
// Pépites Locales
import pepitesLocalesMockupBox from "../../assets/images/portfolio/Pepites_locales/mockup_box.png";
import pepitesLocalesMockupBox2 from "../../assets/images/portfolio/Pepites_locales/mockup_box2.png";
import pepitesLocalesMockupTelephone from "../../assets/images/portfolio/Pepites_locales/mockup_telephone.jpg";
import pepitesLocalesMockupSite from "../../assets/images/portfolio/Pepites_locales/mockup_site.jpg";
import pepitesLocalesMockupTshirt from "../../assets/images/portfolio/Pepites_locales/mockup_tshirt.png";
import pepitesLocalesPageAbonnement from "../../assets/images/portfolio/Pepites_locales/page_abonnement.png";
import pepitesLocalesPageAccueil from "../../assets/images/portfolio/Pepites_locales/page_accueil.png";
import pepitesLocalesPageNosCoffrets from "../../assets/images/portfolio/Pepites_locales/page_nos_coffrets.png";
import pepitesLocalesPanier from "../../assets/images/portfolio/Pepites_locales/page_panier.png";
// SUEZ
import suezMockupBanner from "../../assets/images/portfolio/SUEZ/mockup_banner.jpg";
import suezMockupPosters1 from "../../assets/images/portfolio/SUEZ/mockup_posters1.png";
import suezMockupPosters2 from "../../assets/images/portfolio/SUEZ/mockup_posters2.jpg";
import suezPoster1 from "../../assets/images/portfolio/SUEZ/poster1.png";
import suezPoster2 from "../../assets/images/portfolio/SUEZ/poster2.png";

const Portfolio = () => {
  const { openPopupIndex, setOpenPopupIndex } = usePopupContext();
  const [isMobile, setIsMobile] = useState(false);
  const [isPortfolioOpen, setIsPortfolioOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(flecheGaucheLilas);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1100);
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const adjustSecondActive = () => {
  //   const activeItems = document.querySelectorAll('.portfolio .slick-active');
  //   activeItems.forEach((el, index) => {
  //     if (index === 1) { // Second element (0-based index)
  //       el.style.height = '550px';
  //     } else {
  //       el.style.height = '500px'; // Reset others to 500px
  //     }
  //   });
  // };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    focusOnSelect: true,
    centerMode: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const popupsSuez = [
    {
      title: "Opération SUEZ",
      contentType: "portfolio-text",
      text: "<p>SUEZ nous a sollicité pour la création d'une campagne d'affiche pour promouvoir les règles de sécurité sur le lieu de travail.</p>",
      top: "250px",
      left: "80px",
    },
    {
      title: "Rapport de mission",
      contentType: "portfolio-text",
      text: "<p>Une campagne ayant pour projet de prôner l'humour et l'impact graphique afin de marquer le message dans l'esprit du lecteur.</p>",
      top: "calc(100% - 230px)",
      left: "calc(100% - 480px)",
    },
    {
      contentType: "popup",
      contentImage: suezMockupBanner,
      top: "calc(100% - 500px)",
      left: "20%",
    },
    {
      contentType: "popup",
      contentImage: suezMockupPosters1,
      top: "45%",
      left: "35%",
    },
    {
      contentType: "popup",
      contentImage: suezMockupPosters2,
      top: "40%",
      left: "55%",
    },
    {
      contentType: "popup",
      contentImage: suezPoster1,
      top: "10%",
      left: "30%",
    },
    {
      contentType: "popup",
      contentImage: suezPoster2,
      top: "10%",
      left: "55%",
    },
  ];

  const popupsAvenirInvestissements = [
    {
      title: "Expédition Avenir Investissements",
      contentType: "portfolio-text",
      text: "<p>Avenir Investissements a proposé un appel d'offre pour la stratégie et l'identité graphique de leur prochaine Halle situé à Lille.</p>",
      top: "250px",
      left: "80px",
    },
    {
      title: "Rapport de mission",
      contentType: "portfolio-text",
      text: "<p>Projet graphique décalé de la conccurence.<br />Impact graphique visant à apport une identité forte sur leurs supports !</p>",
      top: "calc(100% - 230px)",
      left: "calc(100% - 480px)",
    },
    {
      contentType: "popup",
      contentImage: avenirInvestissementsMockupApron,
      top: "calc(100% - 300px)",
      left: "10%",
    },
    {
      contentType: "popup",
      contentImage: avenirInvestissementsMockupBag,
      top: "10%",
      left: "calc(10% + 400px)",
    },
    {
      contentType: "popup",
      contentImage: avenirInvestissementsMockupBagGrocer,
      top: "30%",
      left: "40%",
    },
    {
      contentType: "popup",
      contentImage: avenirInvestissementsMockupBanner,
      top: "60%",
      left: "40%",
    },
    {
      contentType: "popup",
      contentImage: avenirInvestissementsMockupFlyer,
      top: "10%",
      left: "40%",
    },
    {
      contentType: "popup",
      contentImage: avenirInvestissementsMockupOutside,
      top: "calc(100% - 300px)",
      left: "calc(100% - 850px)",
    },
    {
      contentType: "popup",
      contentImage: avenirInvestissementsMockupPins,
      top: "45%",
      left: "55%",
    },
    {
      contentType: "popup",
      contentImage: avenirInvestissementsMockupPoster,
      top: "calc(100% - 300px)",
      left: "25%",
    },
    {
      contentType: "popup",
      contentImage: avenirInvestissementsMockupSign,
      top: "25%",
      left: "25%",
    },
    {
      contentType: "popup",
      contentImage: avenirInvestissementsMockupSign2,
      top: "10%",
      left: "55%",
    },
    {
      contentType: "popup",
      contentImage: avenirInvestissementsMockupWall,
      top: "25%",
      left: "calc(100% - 600px)",
    },
  ];

  const popupsPepitesLocales = [
    {
      title: "Exploration Pépites Locales",
      contentType: "portfolio-text",
      text: "<p>Projet de coffrets de dégustation autour de produits locaux.<br />La mission est de lancer le projet en partant de sa création.</p>",
      top: "250px",
      left: "80px",
    },
    {
      title: "Rapport de mission",
      contentType: "portfolio-text",
      text: "<p>Nous avons développé le projet dans son intégralité et accompagné son créateur jusqu'à son lancement !<br />Un projet qui continue sans notre intervention après une bonne formation !</p>",
      top: "calc(100% - 230px)",
      left: "calc(100% - 480px)",
    },
    {
      contentType: "popup",
      contentImage: pepitesLocalesMockupBox,
      top: "55%",
      left: "100px",
    },
    {
      contentType: "popup",
      contentImage: pepitesLocalesMockupBox2,
      top: "8%",
      left: "calc(5% + 400px)",
    },
    {
      contentType: "popup",
      contentImage: pepitesLocalesMockupTelephone,
      top: "calc(100% - 350px)",
      left: "calc(50% - 160px)",
    },
    {
      contentType: "popup",
      contentImage: pepitesLocalesMockupSite,
      top: "calc(100% - 400px)",
      left: "65%",
    },
    {
      contentType: "popup",
      contentImage: pepitesLocalesMockupTshirt,
      top: "45%",
      left: "600px",
    },
    {
      contentType: "popup",
      contentImage: pepitesLocalesPageAbonnement,
      top: "10%",
      left: "55%",
    },
    {
      contentType: "popup",
      contentImage: pepitesLocalesPageAccueil,
      top: "10%",
      left: "68%",
    },
    {
      contentType: "popup",
      contentImage: pepitesLocalesPageNosCoffrets,
      top: "15%",
      left: "45%",
    },
    {
      contentType: "popup",
      contentImage: pepitesLocalesPanier,
      top: "20%",
      left: "calc(100% - 400px)",
    },
  ];

  const popupsNHAcompagnements = [
    {
      title: "Épopée NH Accompagnements",
      contentType: "portfolio-text",
      text: "<p>NH Accompagnements s'est rapproché de nous pour la création d'un site web dans le domaine du coaching professionnel !</p>",
      top: "250px",
      left: "80px",
    },
    {
      title: "Rapport de mission",
      contentType: "portfolio-text",
      text: "<p>Le site se voit aujourd'hui fluctuant et davantage attrayant, permettant à Nicole Hardy de continuer d'évoler sans notre intervention !</p>",
      top: "calc(100% - 230px)",
      left: "calc(100% - 480px)",
    },
    {
      contentType: "popup",
      contentImage: nhAccompagnementsMockupInstaPost,
      top: "50%",
      left: "10%",
    },
    {
      contentType: "popup",
      contentImage: nhAccompagnementsMockupSite,
      top: "10%",
      left: "calc(100% - 500px)",
    },
    {
      contentType: "popup",
      contentImage: nhAccompagnementsMockupTablette,
      top: "25%",
      left: "35%",
    },
    {
      contentType: "popup",
      contentImage: nhAccompagnementsMockupTelephone,
      top: "calc(100% - 400px)",
      left: "45%",
    },
  ];

  const popupsIrisIT = [
    {
      title: "Voyage IRIS IT",
      contentType: "portfolio-text",
      text: "<p>Directement affilié à IRIS IT, nous sommes sollicités pour la communication sous tous aspects.</p>",
      top: "250px",
      left: "80px",
    },
    {
      title: "Rapport de mission",
      contentType: "portfolio-text",
      text: "<p>Des projets constants et variés qui nous poussent à évoluer dans tous nos domaines d'activités !</p>",
      top: "calc(100% - 230px)",
      left: "calc(100% - 480px)",
    },
    {
      contentType: "popup",
      contentImage: irisItMockupCarteVoeux,
      top: "45%",
      left: "10%",
    },
    {
      contentType: "popup",
      contentImage: irisItMockupFlyer,
      top: "10%",
      left: "30%",
    },
    {
      contentType: "popup",
      contentImage: irisItMockupKakemono,
      top: "calc(100% - 400px)",
      left: "40%",
    },
    {
      contentType: "popup",
      contentImage: irisItMockupPlaquetteMetiers,
      top: "45%",
      left: "55%",
    },
    {
      contentType: "popup",
      contentImage: irisItMockupSocialMedia,
      top: "15%",
      left: "65%",
    },
  ];

  const popupsMobileAngelo = [
    {
      title: "Aventure Mobile Angelo",
      contentType: "portfolio-text",
      text: "<p>Mobile Angelo nous a sollicité pour la création de leur site web et la gestion de leurs réseaux sociaux !</p>",
      top: "250px",
      left: "80px",
    },
    {
      title: "Rapport de mission",
      contentType: "portfolio-text",
      text: "<p>Après la création d'un site web opérationnel, nous sommes encore aujourd'hui chargé de communiquer de leurs actualités !</p>",
      top: "calc(100% - 230px)",
      left: "calc(100% - 480px)",
    },
    {
      contentType: "popup",
      contentImage: mobileAngeloMockupBanner,
      top: "calc(100% - 400px)",
      left: "10%",
    },
    {
      contentType: "popup",
      contentImage: mobileAngeloMockupInstaPost,
      top: "20%",
      left: "35%",
    },
    {
      contentType: "popup",
      contentImage: mobileAngeloMockupTelephone,
      top: "20%",
      left: "calc(100% - 600px)",
    },
    {
      contentType: "popup",
      contentImage: mobileAngeloMockupSite,
      top: "55%",
      left: "55%",
    },
  ];

  const portfolioPresentation = [
    {
      title: "SUEZ",
      image: suezPoster2,
      subTitle: "Passer un message !",
      description:
        "Un projet de communication print autour de la sécurité du travail jouant sur l'humour !",
    },
    {
      title: "Avenir Investissements",
      image: avenirInvestissementsMockupFlyer,
      subTitle: "Un lancement frappant !",
      description:
        "Un projet de Halle commerçante où notre stratégie s'est fixée sur cette volonté d'être décalé et donc d'attirer !",
    },
    {
      title: "Pépites Locales",
      image: pepitesLocalesMockupBox,
      subTitle: "Des valeurs et des saveurs !",
      description:
        "Un projet e-commerce prônant des valeurs fortes et transportant des saveurs à chaque coffret !",
    },
    {
      title: "NH Accompagnements",
      image: nhAccompagnementsMockupSite,
      subTitle: "Accompagner et évoluer !",
      description:
        "Un lifting de l'identité graphique d'une firme de coach professionnel afin d'améliorer l'image de marque.",
    },
    {
      title: "IRIS IT",
      image: irisItMockupPlaquetteMetiers,
      subTitle: "Transmettre l'information !",
      description:
        "Sur la base d'une charte déjà établie, nous développons une stratégie pour leur communication !",
    },
    {
      title: "Mobile Angelo",
      image: mobileAngeloMockupSite,
      subTitle: "Gardien de leur intégrité !",
      description:
        "Nous sommes chargés d'assurer la fiabilité de leur site, un suivi constant afin de maintenir son bon fonctionnement !",
    },
  ];

  const handlePopupOpen = (index) => {
    setOpenPopupIndex(openPopupIndex === index ? null : index);
  };

  const openPortfolioItem = (index) => {
    setIsPortfolioOpen(true);
    handlePopupOpen(index);
  };

  const closePortfolio = () => {
    setIsPortfolioOpen(false);
    handlePopupOpen(null);
  };

  return (
    <div
      className={`main-content portfolio ${
        isPortfolioOpen ? "portfolio-opened" : ""
      }`}
    >
      <Helmet>
        <title>Iris Web - Nos projets</title>
        <meta
          name="description"
          content="Découvre les projets que nous avons réalisés pour nos clients."
        />
      </Helmet>
      {isMobile ? (
        <div className="responsive-footer-menu">
          <CarouselPortfolioResponsive />
        </div>
      ) : (
        <div className="portfolio-container">
          {!isPortfolioOpen ? (
            <div className="slider-container">
              <Slider {...settings}>
                {portfolioPresentation.map((portfolioItem, index) => (
                  <div
                    key={index}
                    className="carousel-item color-pink"
                    onClick={() => openPortfolioItem(index)} // Capture l'index de la slide cliquée
                  >
                    <div className="carousel-item-header">
                      <h2>{portfolioItem.title}</h2>
                    </div>
                    <div className="portfolio-item-content">
                      <img
                        src={portfolioItem.image}
                        alt={portfolioItem.title}
                      />
                      <h3>{portfolioItem.subTitle}</h3>
                      <p>{portfolioItem.description}</p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          ) : (
            <div className="inline footer-menu">
              <button
                className="portfolio-return-button"
                onClick={() => closePortfolio()}
                onMouseEnter={() => setImageSrc(flecheGaucheRose)}
                onMouseLeave={() => setImageSrc(flecheGaucheLilas)}
              >
                <img src={imageSrc} alt="Retour" />
                Retour
              </button>
              <DraggablePopupComponent
                popups={popupsSuez}
                buttonImage={rond}
                buttonImageOver={rond_lila}
                buttonText="SUEZ"
                modalIsOpen={openPopupIndex === 0}
                onOpen={() => handlePopupOpen(0)}
              />
              <DraggablePopupComponent
                popups={popupsAvenirInvestissements}
                buttonImage={rond}
                buttonImageOver={rond_lila}
                buttonText="Avenir Investissements"
                modalIsOpen={openPopupIndex === 1}
                onOpen={() => handlePopupOpen(1)}
              />
              <DraggablePopupComponent
                popups={popupsPepitesLocales}
                buttonImage={rond}
                buttonImageOver={rond_lila}
                buttonText="Pépites Locales"
                modalIsOpen={openPopupIndex === 2}
                onOpen={() => handlePopupOpen(2)}
              />
              <DraggablePopupComponent
                popups={popupsNHAcompagnements}
                buttonImage={rond}
                buttonImageOver={rond_lila}
                buttonText="NH Accompagnements"
                modalIsOpen={openPopupIndex === 3}
                onOpen={() => handlePopupOpen(3)}
              />
              <DraggablePopupComponent
                popups={popupsIrisIT}
                buttonImage={rond}
                buttonImageOver={rond_lila}
                buttonText="IRIS IT"
                modalIsOpen={openPopupIndex === 4}
                onOpen={() => handlePopupOpen(4)}
              />
              <DraggablePopupComponent
                popups={popupsMobileAngelo}
                buttonImage={rond}
                buttonImageOver={rond_lila}
                buttonText="Mobile Angelo"
                modalIsOpen={openPopupIndex === 5}
                onOpen={() => handlePopupOpen(5)}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Portfolio;
