import Slider from "react-slick";
import rond from "../../assets/icons/rond_service.png";
import { useState } from "react";
import ResponsiveModalComponent from "../ResponsiveModal";
import "../../styles/services.css";
import ChevronDroitRose from "../../assets/icons/chevron_droite_rose.png";

const CarouselServicesResponsive = () => {
  const [openPopupIndex, setOpenPopupIndex] = useState(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const popupsCreationRefonteSiteWeb = [
    {
      title: "Marre de ton site web qui ne te ressemble plus ?",
      text: "<p>Il est temps de lui donner un coup de jeune et de le rendre plus attractif que jamais !</p> <p>Pas encore de site web ? On te guide de A à Z dans la création d'une vitrine digitale qui se démarque ! </p><h3>Avec nous, ton site web sera :</h3><ul><li>Attrayant et percutant : Un design qui capte l’attention et incite à la découverte.</li><li>Performant : Optimisé pour convertir tes visiteurs en clients.</li><li>Intuitif et accessible : Une interface simple et facile à utiliser pour une navigation fluide.</li></ul><h3>On s'occupe de tout :</h3><ul><li>Création sur-mesure ou relooking total (site vitrine & e-commerce).</li><li>Hébergement et sécurité au top.</li><li>Adapté au mobile et à la tablette.</li><li>Gestion facile, même pour les novices.</li></ul>",
    },
  ];

  const popupsMaintenanceWeb = [
    {
      title: "Pas le temps de t’occuper de la maintenance de ton site web ?",
      text: "<p>On s'en charge pour toi !</p><p>Avec notre service de maintenance, ton site web sera toujours :<ul><li>Performant et sécurisé : Mises à jour régulières, corrections de bugs et surveillance constante.</li><li>Attrayant et à jour : Ajout de nouvelles pages, optimisation du design et des images</li><li>Facile à utiliser : Interface intuitive et accessible pour une navigation fluide.</li></ul><h3>On s'occupe de tout !</h3><ul><li>Ajout de nouveaux articles à ton blog.</li><li>Gestion de ton hébergement et les adresses emails.</li><li>Protection contre les virus et les attaques malveillantes.</li></ul>",
    },
  ];

  const popupsCreationRefonteGraphique = [
    {
      title: "Ta communication manque de cohérence et d'impact ?",
      text: "<p>Il est temps de la dynamiser avec une charte graphique professionnelle !</p><p>Avec notre expertise, ta charte graphique sera :</p><ul><li>Harmonieuse et distinctive : Un design qui reflète fidèlement à ton ADN et tes valeurs.</li><li>Inoubliable et impactante : Une identité visuelle qui marque les esprits et te démarque de tes concurrents.</li><li>Adaptée à tous tes usages : Un logo, des couleurs, une typographie... utilisables sur tous tes supports de communication, du web à l’imprimé.</li></ul><h3>Nous prenons en charge l'intégralité du processus :</h3><ul><li>Création sur-mesure ou refonte complète de ta charte graphique.</li><li>Définition de ton identité visuelle (logo, couleurs, typographie).</li><li>Déclinaison de ta charte graphique sur tous tes supports.</li><li>Guide d'utilisation pour une application cohérente de ta charte graphique.</li></ul>",
    },
  ];

  const popupsCreationPrint = [
    {
      title:
        "Tu veux créer des prints qui captent l'attention et marquent durablement ?",
      text: "<p> Donne vie à tes idées avec des designs percutants.</p><p>Avec notre savoir-faire, tes supports print seront :</p><ul><li>Visuellement attrayants et impactants : Des designs accrocheurs qui captivent l'attention et suscitent l'intérêt.</li><li>Parfaitement adaptés à ton message : Une communication claire et concise qui transmet efficacement tes propos.</li><li>Déclinables sur plusieurs supports : Brochures, flyers, affiches, dépliants et plus encore, pour une identité visuelle cohérente.</li></ul><h3>On s'occupe de tout pour créer tes supports print :</h3><ul><li>Analyse détaillée de tes besoins et objectifs.</li><li>Conception sur mesure en accord avec ta charte graphique.</li><li>Création de supports de haute qualité, avec une attention particulière portée aux détails.</li><li>Dédiés à t’offrir un résultat qui te convient parfaitement.</li></ul>",
    },
  ];

  const popupsReseauxSociaux = [
    {
      title:
        "Envie de dynamiser ta présence en ligne et faire résonner ta marque ?",
      text: "<p>Propulse-la avec des posts percutants.</p><p>Fais équipe avec nos experts en réseaux sociaux pour :</p><ul><li>Développer une stratégie adaptée à tes objectifs de marque.</li><li>Créer du contenu engageant qui touche ton public cible.</li><li>Gérer ta présence sur différentes réseaux sociaux.</li><li>Suivre et analyser tes performances pour mesurer le retour sur investissement.</li></ul><h3>Grâce à notre expertise, tu pourras :</h3><ul><li>Accroître la notoriété et la visibilité de ta marque.</li><li>Générer du trafic vers ton site web et booster tes ventes.</li><li>Renforcer tes relations client et fidéliser ton audience.</li></ul>",
    },
  ];

  const popupsCreationNewsletter = [
    {
      title: "Prêt à booster tes résultats et à tisser des liens ?",
      text: "<p>Lance-toi dans la newsletter dès maintenant pour propulser ton entreprise vers de nouveaux sommets ! </p><p>Fais équipe avec nos experts en marketing par newsletter pour :</p><ul><li>Concevoir des campagnes d'emailing qui captivent tes abonnés.</li><li>Segmenter ton audience pour des messages ciblés et des expériences personnalisées.</li><li>Suivre et analyser les performances de tes newsletters pour mesurer le retour sur investissement.</li></ul><h3>Grâce à notre expertise, tu peux :</h3><ul><li>Fidéliser tes abonnés et renforcer tes relations client.</li><li>Générer du trafic vers ton site web et booster tes ventes.</lI><li>Promouvoir de nouveaux produits et services auprès d'un public réceptif.</li></ul>",
    },
  ];

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    focusOnSelect: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => setActiveSlideIndex(current),
  };

  const handleModalOpen = (index) => {
    setOpenPopupIndex(index);
    if (openPopupIndex === index) {
      setOpenPopupIndex(null);
    }
    // const mainContent = document.querySelector(".main-content");
    // mainContent.classList.add("hide");
  };

  return (
    <div className="carousel-services">
      <button
        onClick={() => handleModalOpen(activeSlideIndex)}
        className="services-see-more"
      >
        En savoir plus <img src={ChevronDroitRose} alt="chevron En Savoir Plus" />
      </button>
      <Slider {...settings}>
        <div className="carousel-services-modal">
          <ResponsiveModalComponent
            modals={popupsCreationRefonteSiteWeb}
            buttonImage={rond}
            buttonText="Site web"
            modalIsOpen={openPopupIndex === 0}
            onOpen={() => handleModalOpen(0)}
          />
        </div>
        <div className="carousel-services-modal">
          <ResponsiveModalComponent
            modals={popupsCreationRefonteGraphique}
            buttonImage={rond}
            buttonText="Charte graphique"
            modalIsOpen={openPopupIndex === 1}
            onOpen={() => handleModalOpen(1)}
          />
        </div>
        <div className="carousel-services-modal">
          <ResponsiveModalComponent
            modals={popupsReseauxSociaux}
            buttonImage={rond}
            buttonText="Réseaux sociaux"
            modalIsOpen={openPopupIndex === 2}
            onOpen={() => handleModalOpen(2)}
          />
        </div>
        <div className="carousel-services-modal">
          <ResponsiveModalComponent
            modals={popupsCreationNewsletter}
            buttonImage={rond}
            buttonText="Newsletter"
            modalIsOpen={openPopupIndex === 3}
            onOpen={() => handleModalOpen(3)}
          />
        </div>
        <div className="carousel-services-modal">
          <ResponsiveModalComponent
            modals={popupsCreationPrint}
            buttonImage={rond}
            buttonText="Supports publicitaires"
            modalIsOpen={openPopupIndex === 4}
            onOpen={() => handleModalOpen(4)}
          />
        </div>
        <div className="carousel-services-modal">
          <ResponsiveModalComponent
            modals={popupsMaintenanceWeb}
            buttonImage={rond}
            buttonText="Maintenance web"
            modalIsOpen={openPopupIndex === 5}
            onOpen={() => handleModalOpen(5)}
          />
        </div>
      </Slider>
    </div>
  );
};

export default CarouselServicesResponsive;
